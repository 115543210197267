<template>
  <div>
    <div class="p-fluid mb-5 card">
      <el-select v-model="value" placeholder="Select ip to send test emails">
        <el-option
          v-for="item in credential"
          :key="item.value"
          :label="'IP adress: ' + item.ip"
          :value="item.ip"
        >
        </el-option>
      </el-select>
    </div>

    <div class="p-fluid p-grid">
      <div class="p-col-6">
        <span class="p-float-label">
          <InputText
            id="from"
            type="text"
            v-model="payload.headers.from_email"
          />
          <label for="from">Email from</label>
        </span>
      </div>
      <div class="p-col-6">
        <span class="p-float-label">
          <InputText id="to" type="text" v-model="payload.to_email" />
          <label for="to">Email to</label>
        </span>
      </div>
    </div>
    <div class="p-fluid p-grid mt-2">
      <div class="p-col-3">
        <span class="p-float-label">
          <InputText id="topic" type="text" v-model="payload.topic" />
          <label for="topic">Topic</label>
        </span>
      </div>
      <div class="p-col">
        <span class="p-float-label">
          <InputText id="bcc" type="text" v-model="payload.headers.bcc_email" />
          <label for="bcc">Email bcc</label>
        </span>
      </div>
    </div>
    <div class="p-fluid mt-2">
      <Editor
        v-model="payload.body"
        @text-change="valueText"
        editorStyle="height: 320px"
      />
    </div>
    <div class="p-fluid mt-2 p-text-left">
      <base-button slot="footer" type="primary" v-on:click="send()" fill
        >Send</base-button
      >
    </div>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import Button from "primevue/button";
import { mailsServices } from "./../services/LxmailServices";
import { Select, Option } from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

export default {
  components: {
    InputText,
    Editor,
    Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      payload: {
        site_title: "LXMAIL API TEST",
        topic: null,
        to_email: null,
        headers: {
          from_email: null,
          bcc_email: null,
        },
        body: null,
      },
      credential: null,
      value: "",
    };
  },

  created() {
    this.credential = this.$store.getters.getUser.credentials;
  },

  methods: {
    send() {
      if (this.value == null || this.value == "") {
        this.$store.dispatch("alert_error", {
          message: "Please select the ip",
          time: 1500,
        });
      } else if (
        this.payload.headers.from_email == null ||
        this.payload.headers.from_email == ""
      ) {
        this.$store.dispatch("alert_error", {
          message: "The email from field was not filled in.",
          time: 1500,
        });
      } else if (this.payload.to_email == null || this.payload.to_email == "") {
        this.$store.dispatch("alert_error", {
          message: "The email to field was not filled in.",
          time: 1500,
        });
      } else if (this.payload.topic == null || this.payload.topic == "") {
        this.$store.dispatch("alert_error", {
          message: "The email topic was not filled in.",
          time: 1500,
        });
      } else if (this.payload.body == null || this.payload.body == "") {
        this.$store.dispatch("alert_error", {
          message: "The body field was not filled in.",
          time: 1500,
        });
      } else {
        this.$store.dispatch("alert_success", {
          message: "Send...",
          time: 1500,
        });

        var credential = this.credential.map((item) => {
          if (item.ip == this.value) {
            if(item.tokenP){
              if(item.token == this.maskToken(item.tokenP)){
                item.token = item.tokenP;
              }else{
                item.token = item.token;
              }
            }
            var payload = {
              credentials: item,
              site: "Backoffice" + item.user_id,
              site_title: this.payload.site_title,
              topic: this.payload.topic,
              to_email: this.payload.to_email,
              headers: {
                from_email: this.payload.headers.from_email,
                bcc_emails: this.payload.headers.bcc_email,
              },
              body: this.payload.body,
            };

            mailsServices
              .test(payload)
              .then((result) => {})
              .catch((err) => {});
          }
        });
      }
    },

    valueText(e) {
      this.body = e.textValue;
    },

    maskToken(token) {
      var numCaracteres = token.length;
      var mask = "";
      for (var i = 0; i < numCaracteres; i++) {
        mask += "*";
      }

      return mask;
    },
  },
};
</script>
<<style scoped src="primeflex/primeflex.css">
</style>
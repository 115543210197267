import axios from "axios";
import env from "../env.js";

export const mailsServices = {
    async test(payload) {
        return axios.post(env.API + "/lxmail/test", payload);
    }
};
export default {
    mailsServices
};